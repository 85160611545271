import PropTypes from 'prop-types';

export const CategoryObjectType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  jobName: PropTypes.string,
  plural: PropTypes.string,
  singular: PropTypes.string,
  noun: PropTypes.string,
  slug: PropTypes.string,
  verb: PropTypes.string
});

export const RatingsObjectType = PropTypes.shape({
  average_rating: PropTypes.number,
  reviews_count: PropTypes.number
});

export const ChoiceTipsType = PropTypes.shape({
  choices: PropTypes.arrayOf(PropTypes.shape({
    choices_subheader: PropTypes.string,
    choices_content: PropTypes.string
  })),
  choices_header: PropTypes.string,
  main_header: PropTypes.string,
  main_tagline: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.shape({
    types_subheader: PropTypes.string,
    types_content: PropTypes.string
  })),
  types_header: PropTypes.string
});

export const EntryPageChoiceTipsType = PropTypes.shape({
  article: PropTypes.shape({
    columnA: PropTypes.shape({
      content: PropTypes.string
    }),
    columnB: PropTypes.shape({
      content: PropTypes.string
    })
  }),
  subtitle: PropTypes.string,
  title: PropTypes.string
});

export const LocationType = PropTypes.shape({
  city: PropTypes.string,
  id: PropTypes.number,
  isRegion: PropTypes.bool,
  postcode: PropTypes.string,
  state: PropTypes.string,
  stateCapitalCity: PropTypes.string,
  suburb: PropTypes.string
});

export const LocationAutoCompleteType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired
});

export const Job = {
  alternate: PropTypes.bool,
  businessName: PropTypes.string,
  businessProfileUrl: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  currentState: PropTypes.string.isRequired,
  description: PropTypes.string,
  feedbackCreatedAt: PropTypes.string,
  feedbackNotes: PropTypes.string,
  feedbackScore: PropTypes.number,
  hired: PropTypes.bool.isRequired,
  isLogin: PropTypes.bool,
  publicDescription: PropTypes.string.isRequired,
  quoteOnJobUrl: PropTypes.string.isRequired,
  quotesCount: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
  suburb: PropTypes.string.isRequired,
  title: PropTypes.string,
  userEmailVerified: PropTypes.bool.isRequired,
  userFacebookVerified: PropTypes.bool.isRequired,
  userFirstName: PropTypes.string.isRequired,
  userFirstNamePossessive: PropTypes.string.isRequired,
  userMobileVerified: PropTypes.bool.isRequired
};

export const JobType = PropTypes.shape(Job);

export const ReviewType = PropTypes.shape({
  byName: PropTypes.string,
  notes: PropTypes.string,
  job: JobType,
  createdAt: PropTypes.string,
  roomAttributes: PropTypes.shape({
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number
  })
});

export const AverageRatingType = PropTypes.shape({
  businessCount: PropTypes.number,
  rating: PropTypes.number,
  reviewsCount: PropTypes.number
});

export const BusinessType = PropTypes.shape({
  _type: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  abn: PropTypes.string,
  avatar: PropTypes.string,
  awards_count: PropTypes.number,
  cached_slug: PropTypes.string,
  wedding_category_slug: PropTypes.string,
  certificate_verified: PropTypes.bool,
  description: PropTypes.string,
  distance: PropTypes.number,
  featured_listing: PropTypes.bool,
  feedback_avg: PropTypes.number,
  feedback_count: PropTypes.number,
  hero_image_id: PropTypes.number,
  hero_image_file_name: PropTypes.string,
  hired_count: PropTypes.number,
  insurance_verified: PropTypes.bool,
  new_to_oneflare: PropTypes.bool,
  on_time_guarantee: PropTypes.bool
});

export const BusinessProfileType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  website: PropTypes.string,
  hiredCount: PropTypes.number,
  newToOneflare: PropTypes.bool,
  servicingZoneNames: PropTypes.arrayOf(PropTypes.string),
  logoId: PropTypes.string,
  logoFileName: PropTypes.string,
  feedbackAvg: PropTypes.number,
  feedbackCount: PropTypes.number,
  userFirstNamePossessive: PropTypes.string,
  description: PropTypes.string,
  feedbacks: PropTypes.arrayOf(PropTypes.shape({
    score: PropTypes.number,
    notes: PropTypes.string,
    byFirstName: PropTypes.string,
    createdAt: PropTypes.string
  })),
  breadcrumbs: PropTypes.shape({
    state: PropTypes.string,
    city: PropTypes.string,
    citySlug: PropTypes.string,
    suburb: PropTypes.string,
    suburbSlug: PropTypes.string,
    category: PropTypes.string,
    categorySlug: PropTypes.string,
    suburbIsCity: PropTypes.bool
  }),
  weddingPrimaryCategory: PropTypes.shape({
    cachedSlug: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    jobName: PropTypes.string,
    name: PropTypes.string,
    plural: PropTypes.string,
    noun: PropTypes.string,
    seoName: PropTypes.string
  }),
  primaryCategory: PropTypes.shape({
    cachedSlug: PropTypes.string,
    id: PropTypes.number,
    jobName: PropTypes.string,
    name: PropTypes.string,
    plural: PropTypes.string,
    noun: PropTypes.string,
    seoName: PropTypes.string
  }),
  nearbyCategoryLocations: PropTypes.arrayOf(PropTypes.shape({
    suburb: PropTypes.string,
    url: PropTypes.string
  }))
});

export const FeedbackType = PropTypes.shape({
  score: PropTypes.number,
  notes: PropTypes.string,
  createdAt: PropTypes.string,
  byFirstName: PropTypes.string,
  replyDate: PropTypes.string,
  replyNotes: PropTypes.string
});

export const locationFromDataType = PropTypes.shape({
  id: PropTypes.number,
  autocomplete: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string
    })
  ),
  state: PropTypes.string,
  suburb: PropTypes.string,
  suburbSlug: PropTypes.string,
  city: PropTypes.string,
  citySlug: PropTypes.string,
  postcode: PropTypes.string,
  stateCapitalCity: PropTypes.string,
  zone: PropTypes.shape({
    id: PropTypes.number,
    zoneHierarchy: PropTypes.shape({
      stateId: PropTypes.number,
      suburbId: PropTypes.number,
      regionId: PropTypes.number
    })
  })
});

export const WithApolloType = PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  query: PropTypes.object,
  pathname: PropTypes.string,
  asPath: PropTypes.string,
  back: PropTypes.func,
  push: PropTypes.func,
  pushTo: PropTypes.func,
  replace: PropTypes.func,
  replaceTo: PropTypes.func
});

export const AutoQuoterType = {
  message: PropTypes.string,
  informationNeeded: PropTypes.bool,
  inspectionNeeded: PropTypes.bool,
  materials: PropTypes.bool,
  hourlyQuote: PropTypes.bool,
  fixedQuote: PropTypes.bool,
  price: PropTypes.string,
  hours: PropTypes.string,
  dailyCreditLimit: PropTypes.string,
  availableInterval: PropTypes.string,
  autoQuoteEnabled: PropTypes.bool,
  autoQuoteVisible: PropTypes.bool,
  blockedDates: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string
    })
  ),
  pausedDate: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string
    })
  )
};

export const CurrentBusinessProfileType = PropTypes.shape({
  abn: PropTypes.string,
  address: PropTypes.string,
  contactNumber: PropTypes.string,
  name: PropTypes.string,
  website: PropTypes.string,
  yearFounded: PropTypes.string
});

export const UserType = PropTypes.shape({
  avatarUrl: PropTypes.string,
  business: PropTypes.shape({
    cachedSlug: PropTypes.string
  }),
  lastChangedPasswordAt: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  displayName: PropTypes.string
});

export const CurrentUserType = PropTypes.shape({
  user: UserType
});

// #region - Wp data types
const sizesType = PropTypes.shape({
  thumbnail: PropTypes.string,
  'thumbnail-width': PropTypes.number,
  'thumbnail-height': PropTypes.number,
  medium: PropTypes.string,
  'medium-width': PropTypes.number,
  'medium-height': PropTypes.number,
  medium_large: PropTypes.string,
  'medium_large-width': PropTypes.number,
  'medium_large-height': PropTypes.number,
  large: PropTypes.string,
  'large-width': PropTypes.number,
  'large-height': PropTypes.number,
  '1536x1536': PropTypes.string,
  '1536x1536-width': PropTypes.number,
  '1536x1536-height': PropTypes.number,
  '2048x2048': PropTypes.string,
  '2048x2048-width': PropTypes.number,
  '2048x2048-height': PropTypes.number,
  'post-thumbnail': PropTypes.string,
  'post-thumbnail-width': PropTypes.number,
  'post-thumbnail-height': PropTypes.number,
  'twentytwenty-fullscreen': PropTypes.string,
  'twentytwenty-fullscreen-width': PropTypes.number,
  'twentytwenty-fullscreen-height': PropTypes.number
});

const imageType = PropTypes.shape({
  ID: PropTypes.number,
  alt: PropTypes.string,
  author: PropTypes.string,
  caption: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  filename: PropTypes.string,
  filesize: PropTypes.number,
  height: PropTypes.number,
  icon: PropTypes.string,
  id: PropTypes.number,
  link: PropTypes.string,
  menu_order: PropTypes.number,
  mime_type: PropTypes.string,
  modified: PropTypes.string,
  name: PropTypes.string,
  sizes: sizesType,
  status: PropTypes.string,
  subtype: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  uploaded_to: PropTypes.number,
  url: PropTypes.string,
  width: PropTypes.number
});

const categoryParentDataType = PropTypes.shape({
  cat_ID: PropTypes.number,
  cat_name: PropTypes.string,
  category_count: PropTypes.number,
  category_description: PropTypes.string,
  category_nicename: PropTypes.string,
  category_parent: PropTypes.number,
  count: PropTypes.number,
  description: PropTypes.string,
  filter: PropTypes.string,
  name: PropTypes.string,
  parent: PropTypes.number,
  slug: PropTypes.string,
  taxonomy: PropTypes.string,
  term_group: PropTypes.number,
  term_id: PropTypes.number,
  term_taxonomy_id: PropTypes.number
});

export const acfType = PropTypes.shape({
  categoryProjects: PropTypes.bool,
  categorySetup: PropTypes.shape({
    jobFormCategory: PropTypes.string,
    pageTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    metaKeywords: PropTypes.string
  }),
  faqs: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired)
  ]),
  heroSetup: PropTypes.shape({
    intro: PropTypes.string,
    backgroundImage: imageType,
    backgroundImageMultiple: PropTypes.arrayOf(imageType)
  }),
  relatedJobFormCategories: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }))
});

export const wpDataType = PropTypes.shape({
  postData: PropTypes.shape({
    acf: PropTypes.shape({
      pageSetup: PropTypes.shape({
        articleSubTitle: PropTypes.string,
        pageTitle: PropTypes.string,
        metaDescription: PropTypes.string,
        metaKeywords: PropTypes.string,
        thumbnail: PropTypes.shape({
          sizes: PropTypes.shape({
            medium: PropTypes.string
          })
        })
      })
    })
  }),
  topLevelCategoryData: PropTypes.arrayOf(PropTypes.shape({
    acf: PropTypes.shape({
      categorySetup: PropTypes.shape({
        pageTitle: PropTypes.string,
        metaDescription: PropTypes.string
      })
    })
  }))
});

export const businessCentreWpDataType = PropTypes.shape({
  categoryData: PropTypes.shape({
    acf: acfType,
    categoryId: PropTypes.number,
    link: PropTypes.string,
    name: PropTypes.string
  }),
  getAllPost: PropTypes.arrayOf(PropTypes.shape({
    acf: PropTypes.shape({
      homePageQuestions: PropTypes.bool,
      pageSetup: PropTypes.shape({
        articleExcerpt: PropTypes.string,
        articleSubTitle: PropTypes.string,
        metaDescription: PropTypes.string,
        metaKeywords: PropTypes.string,
        pageTitle: PropTypes.string,
        thumbnail: imageType
      }),
      related: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.shape({
          abstract: PropTypes.string,
          articleThumbnail: imageType,
          articleTitle: PropTypes.string,
          url: PropTypes.string
        }))])
    }),
    categoryParentData: categoryParentDataType,
    link: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.shape({
      rendered: PropTypes.string
    })
  })),
  topLevelCategoryData: PropTypes.arrayOf(PropTypes.shape({
    acf: acfType,
    categoryId: PropTypes.number,
    link: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string
  }))
});

export const inspirationGetAllPostType = PropTypes.shape({
  getAllPost: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.shape({
      rendered: PropTypes.string.isRequired
    }),
    acf: PropTypes.shape({
      overrides: PropTypes.shape({
        author: PropTypes.string,
        company: PropTypes.string,
        facebook: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
          image: imageType
        }),
        heroTitle: PropTypes.string
      }),
      pageSetup: PropTypes.shape({
        articleSubtitle: PropTypes.string,
        category: PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        }),
        hideHero: PropTypes.bool,
        metaDescription: PropTypes.string,
        metaKeywords: PropTypes.string,
        pageTitle: PropTypes.string
      }),
      relatedPosts: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
          link: PropTypes.string,
          title: PropTypes.string
        })),
        PropTypes.bool
      ])
    }),
    link: PropTypes.string.isRequired,
    modified: PropTypes.string,
    slug: PropTypes.string,
    categoryParentData: categoryParentDataType
  })).isRequired
});
// #endregion

export const oneflareCreditPackType = PropTypes.shape({
  bonusCredits: PropTypes.number,
  expiryDays: PropTypes.number,
  id: PropTypes.string,
  quotingCredits: PropTypes.number
});

export const teamMemberPermissionType = PropTypes.shape({
  leads: PropTypes.string,
  teamMemberManagement: PropTypes.string,
  leadSettings: PropTypes.string,
  autoQuoteSettings: PropTypes.string,
  missionControlSettings: PropTypes.string,
  billingSettings: PropTypes.string,
  quoteActivity: PropTypes.string,
  planDetails: PropTypes.string,
  businessProfile: PropTypes.string
});

export const teamMemberType = PropTypes.shape({
  expiry: PropTypes.number,
  hasFullAccess: PropTypes.bool,
  id: PropTypes.string,
  role: PropTypes.string,
  user: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    phone: PropTypes.string,
    teamMemberPermissions: teamMemberPermissionType
  })
});

export const billingCreditPackType = PropTypes.shape({
  bonusCredits: PropTypes.number,
  expiryDays: PropTypes.number,
  id: PropTypes.string,
  price: PropTypes.number,
  quotingCredits: PropTypes.number
});

export const OneflareLeaseType = PropTypes.shape({
  category: PropTypes.string,
  location: PropTypes.string,
  priceMonthly: PropTypes.number,
  startAt: PropTypes.string,
  endAt: PropTypes.string
});

export const NotificationSettingsType = PropTypes.shape({
  businessMessageAndroid: PropTypes.bool,
  businessMessageEmail: PropTypes.bool,
  businessMessageIos: PropTypes.bool,
  customerMessageEmail: PropTypes.bool,
  jobLeadsAndroid: PropTypes.bool,
  jobLeadsEmail: PropTypes.bool,
  jobLeadsIos: PropTypes.bool,
  jobLeadsSms: PropTypes.bool,
  newsletterEmail: PropTypes.bool,
  newsletterAndroid: PropTypes.bool,
  newsletterIos: PropTypes.bool,
  newsletterSms: PropTypes.bool,
  quoteReceivedEmail: PropTypes.bool,
  quoteReceivedSms: PropTypes.bool,
  quotesSeenDailyEmail: PropTypes.bool,
  quotesSeenInstantAndroid: PropTypes.bool,
  quotesSeenInstantEmail: PropTypes.bool,
  quotesSeenInstantIos: PropTypes.bool,
  weekendSummaryAndroid: PropTypes.bool,
  weekendSummaryEmail: PropTypes.bool,
  weekendSummaryIos: PropTypes.bool,
  weekendSummarySms: PropTypes.bool,
  __typename: PropTypes.string
});

export const BusinessVerificationType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  business: PropTypes.shape({
    name: PropTypes.string,
    abn: PropTypes.string,
    address: PropTypes.string
  }),
  currentState: PropTypes.string,
  abnVerified: PropTypes.bool,
  nameVerified: PropTypes.bool,
  postcodeVerified: PropTypes.bool,
  identityVerified: PropTypes.bool,
  profileNameVerified: PropTypes.bool,
  photoVerified: PropTypes.bool,
  lastAction: PropTypes.string,
  lastActionAt: PropTypes.string,
  approvedBy: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string
  }),
  verifiedAt: PropTypes.string,
  expiredAt: PropTypes.string,
  rejectionReason: PropTypes.arrayOf(PropTypes.string),
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string
});
